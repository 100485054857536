@import '../theme-bootstrap';

.lp-json-pollock.card {
  background-color: $color-white;
  border-radius: 12px;
  box-shadow: 0 0 8px $color-light-gray;
  display: flex;
  margin: 10px 0;
  overflow: hidden;
  padding: 0 !important;
  width: 252px !important;
  .lp-json-pollock-layout-horizontal {
    display: flex !important;
  }
  * {
    border: none !important;
    width: auto !important;
    word-break: normal !important;
  }
  .lp-json-pollock-layout {
    max-width: 100% !important;
  }
  .lp-json-pollock-element-text {
    padding: 0 !important;
  }
  .lp-json-pollock-element-button {
    button {
      color: $color-grey-text !important;
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  /* full-offer-card-image, product-card-image */
  .BrnDej-1, .BrrFen-1 {
    img {
      @include swap_direction(margin, 8px 12px 8px 16px);
      align-items: center;
      border-radius: 6px;
      display: inline-flex !important;
      justify-content: center;
      object-fit: cover;
      overflow: hidden;
      user-select: none;
      width: 54px !important;
    }
  }
  /* product-card-item-color */
  .BrrFen-2 {
    @include swap_direction(margin, 0 6px 8px 0);
    border-radius: 50% !important;
    display: inline-block !important;
    font-size: 0;
    height: 12px !important;
    overflow: hidden;
    user-select: none;
    width: 12px !important;
  }
  /* product-card-color-title */
  .BrrFen-3 {
    color: $gray;
    display: block;
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    margin: 0 0 8px !important;
    max-width: 145px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    word-break: initial;
    word-wrap: initial;
  }
  /* product-card-price-old */
  .BrrFen-4 {
    @include swap_direction(margin, 0 6px 15px 0);
    color: $color-off-black !important;
    display: block !important;
    font-family: $primary-font !important;
    font-size: 12px;
    font-weight: 500 !important;
    line-height: 1;
    max-width: 136px !important;
    overflow: hidden;
    text-decoration: line-through;
    text-overflow: ellipsis;
    user-select: none;
    word-break: initial;
    word-wrap: initial;
  }
  /* product-card-price-new */
  .BrrFen-5 {
    color: $color-warm-grey;
    display: block;
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 15px;
    max-width: 136px;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    word-break: initial;
    word-wrap: initial;
  }
  /* title */
  .BrnDej-2 {
    @include swap_direction(margin, .875rem 1rem .5rem 0);
    color: $color-off-black;
    display: block;
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    max-width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    word-break: initial;
    word-wrap: initial;
    text-transform: capitalize;
  }
  /* Product Description */
  .BrnDej-3 {
    @include swap_direction(margin, 0 12px 6px 0);
    color: $color-off-black;
    display: inline-block !important;
    font-family: $primary-font;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    max-width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    word-break: initial;
    word-wrap: initial;
  }
  /* button-1 */
  .XrfY-1 {
    align-items: center;
    border-top: $border--light !important;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 126px;
    min-height: initial;
    overflow: hidden;
    padding: 10px 1.6px !important;
    text-transform: uppercase;
    text-overflow: ellipsis;
    user-select: none;
    width: 126px !important;
    word-break: initial;
    word-wrap: initial;
  }
  /* button-2 */
  .XrfY-2 {
    align-items: center;
    border-left: $border--light !important;
    border-top: $border--light !important;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 126px;
    padding: 10px 1.6px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    user-select: none;
    width: 126px !important;
    word-break: initial;
    word-wrap: initial;
  }
  /* button-self */
  .XrfY-3 {
    align-items: center;
    border-top: $border--light !important;
    display: inline-flex;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    max-width: 252px;
    overflow: hidden;
    padding: 10px 1.6px !important;
    text-overflow: ellipsis;
    text-transform: uppercase;
    user-select: none;
    word-break: initial;
    word-wrap: initial;
    width: 252px !important;
  }
  /* image-only-card */
  .Rc5Dej-1 {
    img {
      align-items: center;
      display: inline-flex;
      height: 104px !important;
      justify-content: center;
      margin: 0 auto;
      object-fit: cover;
      cursor: pointer;
      user-select: none;
      width: 252px !important;
    }
  }
  /* image-double-button-card */
  .qRnRDej-1 {
    img {
      align-items: center;
      display: inline-flex;
      height: 69px !important;
      justify-content: center;
      margin: 0 auto;
      object-fit: cover;
      user-select: none;
      width: 252px !important;
    }
  }
}
